:root {
  --shadow: 0px 4px 8px rgba(32,33,36,0.50);
  --white: #E8EAED;
  --whiteTransparent: rgba(255,255,255,0.12);
  --lightGray: #9AA0A6;
  --gray: #55557A;
  --black: #202124;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--white);
  background-color: var(--black);
}

h2, .ListItem {
  font-family: 'Orbitron', sans-serif !important;
}

h2 {
  margin: 0;
  font-size: 21px;
  font-weight: 500;
}

.ListItem {
  font-size: 10px;
  letter-spacing: 1px;
}

h1 {
  font-family: 'Michroma', sans-serif;
  font-weight: 900;
  letter-spacing: 0;
  text-transform: uppercase;
  font-size: 32px;
}
